import React, { Component, Fragment } from 'react';
import Progress from './Progress';
import { FiCheck, FiX } from 'react-icons/fi';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import Dropzone from '../../../../atoms/dropzone/Dropzone';
import formMsgs from 'common/dist/messages/form';
import Button from '../../../../atoms/button/Button';
import {Props as DiskUsageProps} from '../../../../molecules/disk-usage/DiskUsage';

export interface Props {
  /** List of files that are currently uploading */
  files: [{}];
  /** Is an upload in progress? */
  uploading: boolean;
  /** Upload progress of the individual files */
  uploadProgress: {};
  /** Was everything successfully uploaded? */
  uploadDone: boolean;
  /** Clear the file list in the Upload window */
  clearFilesList: (uploadId: string) => void;
  /** Add files to the upload list */
  addFiles: (uploadId: string, addedFiles: any) => void;
  uploadFiles: (
    uploadId: string,
    files: any,
    targetDirPath: unknown,
    ensureDirectoriesBeforeUpload: boolean,
    parentType: string,
    appVersionCode: string
  ) => void;
  targetDirPath: string[];
  cancelUpload: (uploadId: string) => void;
  uploadCancelled: boolean;
  error?: string;
  /** Function that is called when the "close" button is clicked */
  onClose?: () => void;
  /** ID of this upload component (used to store the state of the component and to allow for multi-instances of the Upload Component) */
  uploadId?: string;
  /** Show the "close" button? */
  isClosable?: boolean;
  /** Check and create the directories before uploading of required? */
  ensureDirectoriesBeforeUpload?: boolean;
  /** notebook | app */
  parentType?: 'app' | 'notebook';
  /** Only set if parentType='app' */
  appVersionCode?: string;
  data?: DiskUsageProps['data'];
}

export default class Upload extends Component<Props> {
  constructor(props) {
    super(props);

    this.renderActions = this.renderActions.bind(this);
  }

  renderProgress(file) {
    const {
      uploadProgress,
      uploadDone = false,
      uploading = false,
    } = this.props;
    const progress = uploadProgress?.[file.webkitRelativePath || file.name];
    if (uploading || uploadDone) {
      return (
        <div className='progress-wrapper'>
          <Progress progress={progress ? progress.percentage : 0} />
          <div className={'progress-info'}>
            {this.renderProgressInfo(progress)}
          </div>
        </div>
      );
    }
    return <div />;
  }

  renderProgressInfo(progress) {
    if (!progress) return <div />;
    // Should not happen
    else if (progress.status === 'error') {
      return <FiX className={'error-icon'} size={20} />;
    } else if (progress.status === 'done') {
      return <FiCheck className={'check-icon'} size={20} />;
    }
    return (
      <span className={'progress-percentage'}>
        {progress.percentage ? progress.percentage.toFixed(0) : '?'} %
      </span>
    );
  }

  renderActions() {
    const {
      uploading,
      clearFilesList,
      files,
      uploadFiles,
      cancelUpload,
      uploadCancelled,
      uploadDone,
      error,
      onClose,
      uploadId,
      isClosable,
      targetDirPath,
      ensureDirectoriesBeforeUpload,
      parentType,
      appVersionCode,
    } = this.props;

    // if (uploadCancelled) onClose();

    return (
      <Fragment>
        {uploading && (
          <Button
            buttonColor={'orange'}
            withLink={false}
            buttonLabelDefault={'Cancel Upload'}
            disabled={uploadCancelled}
            onClick={() => cancelUpload(uploadId)}
          />
        )}
        <Button
          buttonColor={'transparent'}
          withLink={false}
          buttonLabelDefault={formMsgs.clear.defaultMessage}
          buttonLabelId={formMsgs.clear.id}
          disabled={files?.length <= 0 || uploading}
          onClick={() => clearFilesList(uploadId)}
        />
        {!uploading && !uploadDone && !error && (
          <Button
            buttonColor={'secondary'}
            withLink={false}
            buttonLabelDefault={'Upload'}
            disabled={files?.length <= 0 || uploading}
            onClick={() =>
              uploadFiles(
                uploadId,
                files,
                targetDirPath,
                ensureDirectoriesBeforeUpload,
                parentType,
                appVersionCode
              )
            }
          />
        )}
        {(uploadDone || error) && isClosable && (
          <Button
            buttonColor={'primary'}
            withLink={false}
            buttonLabelDefault={'Close'}
            onClick={() => {
              clearFilesList(uploadId);
              onClose();
            }}
          />
        )}
      </Fragment>
    );
  }

  styledFileSize(size) {
    if (!size && size !== 0) return '';

    // const factor = 1024; -> With factor = 1000 it matches the size displayed by the regular MacOS Finder
    const factor = 1000;

    let reducedSize = size;
    if (reducedSize <= factor) return `${reducedSize.toFixed(1)} Bytes`;

    reducedSize = reducedSize / factor;
    if (reducedSize <= factor) return `${reducedSize.toFixed(1)} KB`;

    reducedSize = reducedSize / factor;
    if (reducedSize <= factor) return `${reducedSize.toFixed(1)} MB`;

    reducedSize = reducedSize / factor;
    return `${reducedSize.toFixed(1)} GB`;
  }

  render() {
    const { files, addFiles, uploading, targetDirPath, uploadId } = this.props;
    return (
      <div className={'upload-container'}>
        <span className={'title'}>Upload Files to AI Workbench</span>
        <div className={'upload-target-container'}>
          <span>Target directory:</span>
          <div className="SelectedDirs noBackground">
          <SelectedDirs dirs={targetDirPath} showTrailingSlash />
          </div>
          {this.props.data ? `Available storage: ${(this.props.data.free / (1024 * 1024 * 1024)).toFixed(2)} GiB` : "Can't access disk-usage."}
        </div>
        <div className={'upload-content'}>
          <Dropzone
            onFilesAdded={async (addedFiles) => addFiles(uploadId, addedFiles)}
            uploading={uploading}
            multiple={true}
            diskFreeRaw={this.props.data ? this.props.data.free : -1}
          />
          {(files && files.length) > 0 ? (
            <div className={'files'}>
              {files.map((file: any) => (
                <div
                  key={file.webkitRelativePath || file.name}
                  className={'row'}
                >
                  <div className={'upload-text'}>
                    <span className={'filename'}>
                      {file.webkitRelativePath || file.name}
                    </span>
                    <span className={'filesize'}>
                      {this.styledFileSize(file.size)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={'tool-description'}>
              <span>
                Drop files on the Upload Files area or click the area to open
                the File Browser.
                <br />
                Uploaded files will be put into the selected Target Directory.{' '}
              </span>
            </div>
          )}
        </div>
        <div className={'actions'}>{this.renderActions()}</div>
      </div>
    );
  }
}
