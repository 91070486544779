import React, { FC, useEffect, useMemo } from 'react';
import NotebookCellWrapper from '../../NotebookCellWrapper';
import {
  Field,
  FormErrors,
  InjectedFormProps,
  reduxForm,
  WrappedFieldProps,
} from 'redux-form';
import TextInputLine from '../../../../../../../atoms/input-elements/text-input-line/TextInputLine';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDataSourceCode,
  updatePasswordVariable,
  updateUsernameVariable,
} from '../../../../../../../../redux/workbench/modules/cells.app.module';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { fetchDataSources } from '../../../../../../../../redux/modules/data.dataSources.module';
import { RootState } from '../../../../../../../../store/store';
import classNames from 'classnames';
import styles from './styles.module.scss';
import {
  ReduxActUnknown3,
  ReduxActUnknown4,
} from '../../../../../../../../store/reduxAct';
import {
  DataSource,
  DSRoles,
  DSType,
} from 'common/dist/types/dataManagement/dataSource';
import DropdownSelectInput from '../../../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

const field: (
  name: string,
  placeholder: string,
  label: string
) => FC<WrappedFieldProps> = (name, placeholder, label) => (props) => {
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched, asyncValidating },
  } = props;
  return (
    <TextInputLine
      touched={touched}
      error={error}
      valid={!error}
      disabled={false}
      id={name}
      name={name}
      hasLabel={true}
      labelDefault={label}
      placeholderDefault={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      validating={asyncValidating}
    />
  );
};

const DataSourceSelector: FC<
  WrappedFieldProps & {
    dataSourceOptions: { label: string; value: string }[];
    credentials: ToBeRefined;
  }
> = (props) => {
  const { input, meta, dataSourceOptions, credentials } = props;

  const dataSourceOptionsLength = (dataSourceOptions || []).length;

  useEffect(() => {
    if (dataSourceOptionsLength > 0) {
      input.onChange(
        dataSourceOptions.find(
          (dso) => dso.value === credentials?.dataSourceCode
        )
      );
    }
  }, [dataSourceOptionsLength]);

  return (
    <DropdownSelectInput
      value={input.value}
      options={dataSourceOptions}
      onChange={input.onChange}
      id='dataSourceCode'
      name='dataSourceCode'
      clearable={false}
      disabled={false}
      label={{
        id: 'no-id',
        defaultMessage: 'Data Source',
      }}
      error={meta.error}
      touched={meta.touched}
      valid={!!meta.error}
    />
  );
};

export type Props = {
  cell: ToBeRefined;
  path: ToBeRefined;
  metadata: ToBeRefined;
  changeSource: (data: {
    path: string;
    cellId: string;
    source: string;
  }) => void;
};

const CredentialsCellInner: FC<Props & InjectedFormProps<FormData, Props>> = (
  props
) => {
  const dataSources = useSelector<RootState, DataSource[]>(
    (state) => state.data.dataSources.data || []
  ).filter(
    (d) =>
      d.ds_type === DSType.Cassandra ||
      (d.ds_type === DSType.S3 &&
        (d.role === DSRoles.HostMount || d.role === DSRoles.RGW))
  );
  const dataSourceOptions = dataSources.map((d) => ({
    label: d.name,
    value: d.code,
  }));

  const UsernameVar = useMemo(
    () => field('usernameVar', 'username', 'Variable to hold the Username'),
    []
  );
  const PasswordVar = useMemo(
    () => field('passwordVar', 'password', 'Variable to hold the Password'),
    []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    props.changeSource({
      path,
      cellId: cell.id,
      source: `print('Please fill the fields.')`,
    });
  }, []);

  useEffect(() => {
    dispatch(fetchDataSources());
  }, [dispatch]);
  const { path, cell } = props;
  const credentials = cell.as_credentials?.cassandra || cell.as_credentials?.s3;

  return (
    <div className={'editor-parent-container'}>
      <div className={classNames('editor-container', styles.credentialsCell)}>
        <div className={styles.headline}>Credentials</div>
        <form className={styles.form}>
          <div className={styles.field}>
            {/* @ts-ignore */}
            <Field
              name='dataSourceCode'
              component={DataSourceSelector}
              value={dataSourceOptions.find(
                (dso) => dso.value === credentials?.dataSourceCode
              )}
              onChange={(event, newDso) => {
                const newCode = newDso?.value;
                const dataSource = dataSources.find(
                  (ds) => ds.code === newCode
                );
                if (dataSource) {
                  dispatch(
                    (updateDataSourceCode as ReduxActUnknown4)(
                      path,
                      cell.id,
                      newCode,
                      dataSource.ds_type
                    )
                  );
                }
              }}
              dataSourceOptions={dataSourceOptions}
              credentials={credentials}
            />
          </div>
          {props.metadata?.kernelspec?.name !== 'sparkkernel' &&
            props.metadata?.kernelspec?.name !== 'pysparkkernel' && (
              <>
                <div className={styles.field}>
                  <Field
                    name='usernameVar'
                    component={UsernameVar}
                    value={credentials?.username}
                    onChange={(event, newValue) =>
                      dispatch(
                        (updateUsernameVariable as ReduxActUnknown3)(
                          path,
                          cell.id,
                          newValue
                        )
                      )
                    }
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    name='passwordVar'
                    component={PasswordVar}
                    value={credentials?.password}
                    onChange={(event, newValue) =>
                      dispatch(
                        (updatePasswordVariable as ReduxActUnknown3)(
                          path,
                          cell.id,
                          newValue
                        )
                      )
                    }
                  />
                </div>
              </>
            )}
        </form>
      </div>
    </div>
  );
};

export const CredentialCellForm = 'credentialCellForm';

type FormData = {
  dataSourceCode: { label: string; value: string };
  usernameVar: string;
  passwordVar: string;
};

const CredentialsCell: FC<Props> = (props) => {
  const { cell } = props;
  // This is used as a dependency for the following useMemo call to only rerender the form with changed initial values
  // if the credentials change from undefined to some value.
  // If the credentials already had some meaningful value, the changes are handled within the form itself.
  // We only have to do this from the outside for the initial values... because redux-form I guess.
  // This is evil because you should not rely on useMemo to fix the logic
  const credentialsLoaded = !!cell.as_credentials;
  const credentials = cell.as_credentials?.cassandra || cell.as_credentials?.s3;
  const WrappedForm = useMemo(
    () =>
      reduxForm<FormData, Props>({
        // Evil hack on top of an evil hack to fix a bug that probably happens when useMemo fails and the form somehow gets recreated
        form: CredentialCellForm + cell.id + Date.now(),
        validate(values: FormData): FormErrors<FormData> {
          return {
            dataSourceCode:
              // @ts-ignore TODO is .length broken?
              values.dataSourceCode && values.dataSourceCode.length > 0
                ? undefined
                : 'Specify data source code.',
            usernameVar:
              values.usernameVar && values.usernameVar.length > 0
                ? undefined
                : 'Specify username variable.',
            passwordVar:
              values.passwordVar && values.passwordVar.length > 0
                ? undefined
                : 'Specify password variable.',
          };
        },
        initialValues: {
          dataSourceCode: credentials?.dataSourceCode,
          usernameVar: credentials?.username,
          passwordVar: credentials?.password,
        },
      })(CredentialsCellInner),
    [cell.id, credentialsLoaded]
  );
  return <WrappedForm {...props} />;
};

export default class CredentialsCellWrapper extends NotebookCellWrapper {
  ChildComponent: React.ComponentType = CredentialsCell;
  type = 'credentials';
  name = 'Credentials';
  parentContainerClass = 'app-cell';
  executable = true;
}
