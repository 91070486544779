import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { metaFilename as repositoryMetaFilename } from '../../part-right/config';
import { PYTHON3_NOTEBOOK } from 'common/dist/constants/notebookTypes';
import Button from '../../../atoms/button/Button';

export default class RepositoryWarning extends Component {
  /**
   * Returns the meta file if it exists, and the main.iypnb is missing (-> This means the warning needs to be displayed)
   * @returns {undefined}
   */
  getMetaFile() {
    const { selectedContent } = this.props;
    if (!selectedContent) return undefined;
    return selectedContent.find((sc) => sc.name === repositoryMetaFilename);
  }

  metaFileIfArchetypePartIsMissing() {
    const { selectedContent } = this.props;
    if (!selectedContent) return undefined;
    // 1. Check whether the meta file exists
    const metaFile = selectedContent.find(
      (sc) => sc.name === repositoryMetaFilename
    );
    if (!metaFile) return undefined;

    const foundAllParts = [
      'learning.ipynb',
      'evaluation.ipynb',
      'prediction.ipynb',
    ].every((nb) => selectedContent.map((sc) => sc.name).includes(nb));
    if (!foundAllParts) {
      // At least one of the learning/evaluation/prediction notebooks is missing
      return metaFile;
    } else {
      return undefined;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activePath, loadRepoMeta, selectedContent } = this.props;
    if (
      prevProps.activePath !== activePath || // Path changed
      (prevProps.selectedContent || []).length !==
        (selectedContent || []).length
    ) {
      // Or content was loaded

      const metaFile = this.getMetaFile();
      if (metaFile && metaFile.path) {
        loadRepoMeta(metaFile.path);
      }
    }
  }

  renderForApp() {
    const { selectedContent, addNotebook } = this.props;
    if (!selectedContent) return null; // Fast exit.
    const metaFile = this.getMetaFile();
    if (!metaFile) return null;

    // Inside a repository AND no main.ipynb notebook.
    // --> Check whether it's an app
    return (
      <div className={'repository-warning-parent'}>
        <span className={'rw-headline'}>Warning: Missing Main Notebook</span>
        <span className={'rw-description'}>
          The Main Notebook is required to build AI Apps. Do you want to create
          it?
        </span>
        <div className={'rw-button-container'}>
          <Button
            buttonColor={'orange'}
            withLink={false}
            buttonLabelDefault={'Create Main Notebook'}
            onClick={() =>
              addNotebook('main.ipynb', PYTHON3_NOTEBOOK, 'Python Default', {})
            }
          />
        </div>
      </div>
    );
  }

  renderForArchetype() {
    const { selectedContent, activePath, loadArchetypeExample } = this.props;
    if (!selectedContent) return null; // Fast exit.

    const metaFile = this.metaFileIfArchetypePartIsMissing();
    if (!metaFile) return null;

    // Inside a repository AND no main.ipynb notebook.
    // --> Check whether it's a code-capsule or an app
    return (
      <div className={'repository-warning-parent'}>
        <span className={'rw-headline'}>Warning: Missing Notebook</span>
        <span className={'rw-description'}>
          An Archetype must consist of a learning, an evaluation and a
          prediction notebook. Currently, at least one of them is missing. Do
          you want to create the missing parts now?
        </span>
        <div className={'rw-button-container'}>
          <Button
            buttonColor={'orange'}
            withLink={false}
            buttonLabelDefault={'Create example notebooks'}
            onClick={() => loadArchetypeExample(activePath, 'adult-bn.tgz')}
          />
        </div>
      </div>
    );
  }

  render() {
    const { repoMeta, selectedContent } = this.props;

    if (
      repoMeta &&
      ['app'].includes(repoMeta.repoType) &&
      selectedContent.filter((x) => x.name === 'main.ipynb').length === 0
    ) {
      return this.renderForApp();
    } else if (
      repoMeta &&
      ['archetype'].includes(repoMeta.repoType) &&
      selectedContent.filter((x) =>
        ['learning.ipynb', 'evaluation.ipynb', 'prediction.ipynb'].includes(
          x.name
        )
      ).length === 0
    ) {
      return this.renderForArchetype();
    } else {
      return null;
    }
  }
}
RepositoryWarning.propTypes = {
  selectedContent: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      // ... and some more fields, that aren't of interest here.
    })
  ),
  repoMeta: PropTypes.shape({}),
  loadRepoMeta: PropTypes.func.isRequired,
  activePath: PropTypes.string.isRequired,
  addNotebook: PropTypes.func.isRequired,
  loadArchetypeExample: PropTypes.func.isRequired,
};
